<template>
  <div class="courseupload">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item>课程资料</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.title}}</el-breadcrumb-item>
      <el-breadcrumb-item>{{prames.type}}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="upload">
      <p class="LessonNo"><span class="bold">{{prames.LessonNo}}</span>（上传图片）</p>
      <div class="uploadwarp" v-for="(item,index) of imgList" :key="index">
        <div class="upload_delete"  v-show="!hasStudentFile"  @click="deleteimg(item,'imgList')">删除</div>
          <el-image 
            style="width: 100px; height: 100px"
            :src="item" 
            :preview-src-list="imgList">
          </el-image>
          <el-image 
            style="width: 100px; height: 100px"
            :src="item" 
            :preview-src-list="previewList">
          </el-image>
      </div>
        <div class="uploadwarp" v-for="(item,index) of uploadimgList" :key="index+'up'">
        <div class="upload_delete"  @click="deleteimg(item,'uploadimgList')">删除</div>
          <el-image 
            style="width: 100px; height: 100px"
            :src="item" 
            :preview-src-list="previewList">
          </el-image>
      </div>
      <i class="el-icon-plus avatar-uploader-icon" v-show="!hasStudentFile" @click="fileClick"></i> 
      <input type="file" name="filesid" id="filesid" ref="files" multiple  v-on:change="handleFilesUpload($event)" style="display: none"/>
    </div>
    <p class="btnp" v-if="hasStudentFile"><el-button type="primary" @click="hasStudentFile=false">编辑</el-button></p>
    <p class="btnp" v-else><el-button type="primary" color="#6f72ff" @click="submitUpload">提交</el-button></p>
  </div>
</template>

<script>
import {get_picture_submit, picture_edit,picture_upload } from '@/api/classesinfo'
import axios from 'axios'
export default {
  name: 'coursemater_courseupload',
  data () {
    return {
      prames:{},
      formData:'',
      imgList:[],
      hasStudentFile:false,
      uploadimgList:[],
      picloadend:false,
      isfirst:false
    };
  },
  created () {
    var prames = sessionStorage.getItem('stu_coursemater_query')? JSON.parse(sessionStorage.getItem('stu_coursemater_query')):{}
    this.prames=prames;
    this.getInit()
  },
  computed:{
    previewList(){
      let list=this.imgList.concat(this.uploadimgList)
      return list
    }
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    fileClick(){  //点击选择图片按钮
      this.$refs.files.value='';
      document.getElementById('filesid').value='';
      document.getElementById('filesid').click()
    },
    //获取所有图片
    async getInit () {
       var prames ={
        userid:this.prames.userid,
        DBType:this.prames.DBType,
        OprId:this.prames.OprId,
        LessonID:this.prames.LessonID,
        UploadType:this.prames.UploadType
      }
      let res = await get_picture_submit(prames)
      if (res.flag == '1') {
        let StudentFile = res.datainfo.StudentFile;
        this.hasStudentFile = StudentFile.length>0?true:false;
        this.isfirst = StudentFile.length>0?true:false;
        this.imgList=StudentFile;
        this.uploadimgList=[];
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    //触发上传按钮
    handleFilesUpload(el){   //一定要在这选择一次时，append一次
      let filesArr=this.$refs.files.files;
      let uplen=this.uploadimgList.length;
      let nowlen=this.imgList.length;
      let curlen=filesArr.length;
      if(uplen+nowlen+curlen>9){
        this.$message.error("最多选择9张图片");
        return false
      }
      for( var i = 0; i < filesArr.length; i++ ){
         if (!filesArr[i].type.match(/.jpg|.png|.jpeg|.bmp/i)) {　　//判断上传文件格式
          this.$message.error("上传的图片格式不正确，请重新选择");
          return false;
        }       
        var formData = new FormData();
        formData.append("userid", this.prames.userid);
        formData.append('picturefile[]',filesArr[i]);
        this.uploadimg(formData)
      }
    },
    async uploadimg(formData){
      this.picloadend=true;
        var res=await picture_upload(formData)
        if (res.flag == '1') {
          let PictureUrl = res.datainfo.PictureUrl;
          this.uploadimgList.push(PictureUrl[0]);
          this.picloadend=false;
          this.isfirst =true;
        }else if(res.flag =='-1'){
          this.$store.dispatch("logout",{
            tips: '登录失效,请重新登录'
          });
          this.picloadend=false;
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
          this.picloadend=false;
          this.$toast('图片上传失败');
        }   
        this.$refs.files.value=''
        this.$forceUpdate();     
    },
    //编辑时删除图片
    deleteimg(item,arr){
      var imgList=JSON.parse(JSON.stringify(this[arr]));
      imgList.forEach((element,index) => {
        if(element==item)(
          imgList.splice(index,1)
        )
      });
      this[arr]=imgList;
    },
    //提交
    async submitUpload(){
      let list=this.imgList.concat(this.uploadimgList)
      if(!this.isfirst){
        return false
      }
      let PictureUrl=list.join(',');
      var prames ={
        userid:this.prames.userid,
        DBType:this.prames.DBType,
        OprId:this.prames.OprId,
        LessonID:this.prames.LessonID,
        UploadType:this.prames.UploadType,
        PictureUrl:PictureUrl
      }
      let res = await picture_edit(prames)
      if (res.flag == '1') {
        this.imgList=[];
        this.uploadimgList=[];        
        setTimeout(()=>{
          this.getInit()
        },50)
        this.$message.success('提交成功');
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    }
  },

}
</script>
<style  lang="scss" scoped>

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  border: 1px dashed #d9d9d9;
  border-radius: 5px;
}
.btnp{
  text-align: center;
  margin-top:50px;
}
.upload{
  overflow: hidden;
  .LessonNo{
    margin-bottom: 20px;
  }
  .uploadwarp {
    position: relative;
    height: 100px;
    line-height: 100px;
    width: 100px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 0px 5px 5px 0px;
    float: left;
    display: table-cell;
    text-align: center;
    background-color: #eee;
    overflow: hidden;
    cursor: pointer;
      .upload_delete {
        position: absolute;
        top: 0;
        right:0;
        line-height: normal;
        background: rgba(89,85,169,0.5);
        font-size: 10px;
        color: #fff;
        padding:2px 5px;
        border-radius: 0 5px 0 5px;
        z-index: 100;
      }
      img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  }
}
</style>